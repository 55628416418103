import {
  Button,
  Iconography,
  Typography,
  TypographyVariant,
} from "@hid-galaxy-ui/galaxy-react";
import { IconographySizes } from "@hid-galaxy-ui/galaxy-react/components/Iconography";
import { useState } from "react";
import Device from "../../../../assets/images/device.png";
import { getUTCTime } from "../../../../utils/linq_utils";
export interface IReaderInfo {
  readerData: {
    name: string;
    firmwareVersion: string;
    lastUpdated: string;
    readerId: string;
    friendlyName?: string
  }[];
}
export default function ReaderInfo(props: IReaderInfo) {
  const { readerData } = props;
  const [currentReaderIndex, setCurrentReaderIndex] = useState<number>(0);
  const selectedReader = readerData[currentReaderIndex];
  return (
    <div className="reader-info">
      <>
        <div className="hid-border-bottom">
          <div className="hid-flex hid-flex-jc-center hid-flex-wrap ">
            <div className="reader-info__count">{`${currentReaderIndex + 1}/${
              readerData.length
            }`}</div>
            <div className="hid-flex hid-flex-ai-center">
              <Button
                variant="secondary"
                icon={
                  <Iconography
                    icon="angleLeft"
                    size={IconographySizes.Medium}
                  />
                }
                data-testid="previous-button"
                onClick={() => setCurrentReaderIndex(currentReaderIndex - 1)}
                disabled={currentReaderIndex === 0}
              />
            </div>
            <img src={Device} alt="device" />
            <div className="hid-flex hid-flex-ai-center">
              <Button
                variant="secondary"
                icon={
                  <Iconography
                    icon="angleRight"
                    size={IconographySizes.Medium}
                  />
                }
                data-testid="next-button"
                onClick={() => setCurrentReaderIndex(currentReaderIndex + 1)}
                disabled={currentReaderIndex >= readerData.length - 1}
              />
            </div>
          </div>
          <Typography
            variant={TypographyVariant.BodyShortProduct}
            className="hid-layout__mb-02 hid-text-center"
          >
            {selectedReader?.friendlyName && selectedReader?.friendlyName?.length>=1  ? selectedReader?.friendlyName : selectedReader?.name}
          </Typography>   
        </div>
        <div className="hid-layout__p-04">
          <Typography
            variant={TypographyVariant.BodyShortProduct}
            className="hid-layout__mb-02"
          >
            <Iconography icon="lockHashtag" className="hid-layout__mr-01" />
            {selectedReader?.name}
          </Typography>
          <Typography
            variant={TypographyVariant.BodyShortProduct}
            className="hid-layout__mb-02"
          >
            <Iconography icon="arrowsRepeat" className="hid-layout__mr-01" />
            {selectedReader?.firmwareVersion}
          </Typography>
        </div>
      </>
    </div>
  );
}
