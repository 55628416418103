import { Card, InlineStatus, InlineStatusAppearance, InlineStatusIconType, Typography } from "@hid-galaxy-ui/galaxy-react"
import { Keys } from "."
import { TypographyVariantEnum } from "@hid-galaxy-ui/galaxy-react/components/Typography/typographyEnum"
import { useTranslation } from "react-i18next";
import authorizedkey from '../assets/icons/authorizedkey.svg';
import { getUTCDate } from "../../../utils/linq_utils";
import { NAMESPACE } from "../../../utils/i18nUtils";
import './_authorizedKeys.scss';


interface Props {
    data:Keys[]
}

const ListView = (props:Props) => {
    const {t} = useTranslation(NAMESPACE.READER)
    const isAdmin = () => {
      return props.data?.some((a)=>a?.issuedOn)
    }
    return (
        <>
        {props.data?.map((key:any,index:any)=>
            <div className="listContainer" key={index}>
              <div className="leftPane">
              <img src={authorizedkey} alt="key"/>
              </div>
              <div className="rightPane">
              <Typography variant={TypographyVariantEnum.Label}>{key?.reference || ""}</Typography><br/>
             {isAdmin() && <Typography data-testid="listViewIssuedDate" variant={TypographyVariantEnum.TextHelper}>{t("READERS.ISSUEDATE")} {getUTCDate(key?.issuedOn) !== "Invalid Date" ? getUTCDate(key?.issuedOn) :  "Invalid"}</Typography>}<br/>
             {isAdmin() && <Typography variant={TypographyVariantEnum.Label}>   <InlineStatus
            appearance={InlineStatusAppearance.Info}
            iconType={InlineStatusIconType.Dot}/> 
            {t("READERS.EXPIRYDATE")} {getUTCDate(key?.expiresOn) !== "Invalid Date" ? getUTCDate(key?.expiresOn) : "Invalid" }</Typography>}
              </div>
            </div>
            
        ) }
        </>
    )
}

export default ListView